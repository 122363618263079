// Get all "navbar-burger" elements
const $navbarBurgers = [...document.querySelectorAll('.navbar-burger')];

// Check if there are any navbar burgers
if ($navbarBurgers.length > 0) {
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
    });
  });
}
